.container {
  position: relative;
}

.loadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  background: rgb(255 255 255 / 80%);
}

.loadingIndicator {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  width: 100%;
}
