@import "src/styles/colors";
@import "src/styles/breakpoints.module";

.textField {
  width: 100%;
  display: flex;
  flex-direction: column;

  .labelBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      color: $default;
      font-size: 14px;
      font-weight: 600;
    }

    &.error label {
      color: $orange !important;
    }
  }

  p {
    color: $orange;
    font-style: italic;
    font-weight: 300;
    font-size: 12px;
    text-align: left;
  }

  .inputBox {
    display: flex;
    align-items: center;
    border: 1px solid $gray_light;
    border-radius: 4px;
    padding: 5px 10px;

    &.inputBlock {
      display: block;
    }

    &.error {
      border-color: $orange;
    }

    &.reducedPadding {
      padding: 0 10px;
    }

    select {
      appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 3px;
    }

    input,
    select {
      border: none;
      outline: none;
      font-size: 14px;
      height: 30px;
      font-weight: 400;
      flex: 1;
      background-color: transparent;

      &:disabled {
        color: $gray_light;

        &::placeholder {
          color: $gray_light;
        }
      }
    }
  }

  .errorMessage {
    line-height: 16px;
    margin-top: 5px;
  }
}

.gutterBottom {
  margin-bottom: 12px;

  @media (min-width: $md) {
    margin-bottom: 24px;
  }
}
