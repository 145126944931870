.componentLoadingIndicator {
  .cssloadSpeedingWheel {
    width: 45px;
    height: 45px;
    margin: 0 auto;
    border: 3px solid rgb(0 0 0);
    border-radius: 50%;
    border-left-color: transparent;
    border-right-color: transparent;
    animation: cssload-spin 575ms infinite linear;

    &.white {
      border: 3px solid white;
      border-left-color: transparent;
      border-right-color: transparent;
    }
  }

  @keyframes cssload-spin {
    100% {
      transform: rotate(360deg);
    }
  }
}
