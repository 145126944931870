@import "src/styles/colors";
@import "src/styles/breakpoints.module";

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $gray_light;
  border-radius: 10px;
}

.size {
  &-small {
    width: 80px;
    height: 80px;

    @media (max-width: $sm) {
      width: 60px;
      height: 60px;
    }
  }

  &-base {
    width: 176px;
    height: 176px;
  }
}

.image {
  border-radius: 10px;
  width: 95%;
  height: 95%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
