.form {
  margin-top: 30px;
}

.codeLinks {
  margin-top: 30px;
}

.codeLink {
  display: block;
}
