@import "src/styles/breakpoints.module";

.phoneNumberField {
  display: flex;
  align-items: flex-end;
}

.textField {
  margin-bottom: 0;
}

.gutterBottom {
  margin-bottom: 12px;

  @media (min-width: $md) {
    margin-bottom: 24px;
  }
}

.countryBox {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 64px;
  height: 40px;

  .flag {
    display: flex;
    height: 40px;
    width: 42px;
    transition-delay: 1s;
    opacity: 1;
    position: relative;

    &.disabled {
      opacity: 0.3;
    }
  }

  .select {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    z-index: 1;
  }
}
