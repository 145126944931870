@import "src/styles/breakpoints.module";

.template {
  display: flex;
  flex-direction: column;
  position: relative;

  .content {
    justify-content: center;
  }

  .backButton {
    margin-top: 20px;
    position: absolute;
    align-self: flex-start;
    background: none;
    outline: none;
    border: none;
    padding: 0 20px 20px 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .backText {
      margin-left: 8px;
    }
  }
}

@media (min-width: $sm) {
  .template {
    .backButton {
      margin-top: 54px;
    }
  }
}
