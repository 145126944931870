@import "src/styles/colors";

.link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}
