@import "src/styles/breakpoints.module";

.login {
  text-align: center;
  margin-top: 60px;
}

.logoContainer {
  display: flex;
  justify-content: center;
}

@media (min-width: $sm) {
  .login {
    margin-top: 20vh;
  }
}
