@import "src/styles/colors";

$footer_height: 80px;
$footer_padding: 20px;

.body {
  &::after {
    content: "";
    display: block;
    height: $footer_height + $footer_padding;
  }
}

.wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer_height;
}

.footer {
  padding: 10px 0;
  border-top: 1px solid $gray_light;
}
