@import "src/styles/colors";
@import "src/styles/breakpoints.module";

.button {
  display: inline-block;
  line-height: 24px;
  font-weight: 600;
  border-radius: 4px;
  border: 2px solid transparent;
  cursor: pointer;
  box-sizing: border-box;
  padding: 8px 10px;
  font-size: 16px;
  min-width: 180px;
  text-decoration: none;
  outline: none;

  &.fullWidth {
    display: block;
    width: 100%;
    min-width: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.text-left {
    text-align: left;
  }

  &.text-center {
    text-align: center;
  }

  &.text-right {
    text-align: right;
  }

  &.gutterBottom {
    margin-bottom: 15px;
  }
}

.primary {
  background-color: $yellow;
  border-color: $yellow;
  color: $default;

  &:hover {
    background-color: lighten($yellow, 10%);
    border-color: lighten($yellow, 10%);
  }
}

.secondary {
  background-color: $white;
  border-color: $yellow;
  color: $default;

  &:hover {
    background-color: lighten($yellow, 10%);
    border-color: lighten($yellow, 10%);
  }
}

.secondaryOutline {
  background-color: transparent;
  border-color: $yellow;
  color: $yellow;

  &:hover {
    background-color: lighten($yellow, 10%);
    border-color: lighten($yellow, 10%);
    color: $default;
  }
}

.tertiary {
  background-color: $orange;
  border-color: $orange;
  color: $white;

  &:hover {
    background-color: lighten($orange, 10%);
    border-color: lighten($orange, 10%);
  }
}

.disabled {
  background-color: $gray_light;
  border-color: $gray_light;
  cursor: auto;
  color: $gray_lighter;

  &:hover {
    background-color: $gray_light;
    border-color: $gray_light;
  }
}

.small {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 14px;
  min-width: 110px;
}

@media (min-width: $sm) {
  .desktopSize {
    &-default {
      padding: 8px 10px;
      font-size: 16px;
      min-width: 180px;
    }

    &-small {
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 14px;
      min-width: 110px;
    }
  }
}
